import {
    ClassicUiEmbeddedMenuLeafDTO,
    DashboardsEmbeddedMenuLeafDTO,
    MenuDTO,
    NewUiMenuLeafDTO,
} from "generated/models"

export enum ContentType {
    HELP = "HELP",
    NEWS = "NEWS",
}

export interface MenuLeafDTO {
    title: string
    path: string
    icon?: string
    treeType:
        | "NewUiMenuLeafDTO"
        | "DashboardsEmbeddedMenuLeafDTO"
        | "ClassicUiEmbeddedMenuLeafDTO"
        | "ASONewUiMenuLeafDTO"
    campaignSelectionRequired: boolean
    hidden: boolean
}

export const isNewUiMenuLeaf = (menuDTO: MenuDTO): menuDTO is NewUiMenuLeafDTO => {
    return menuDTO.treeType === "NewUiMenuLeafDTO"
}
export const isASONewUiMenuLeaf = (menuDTO: MenuDTO): menuDTO is NewUiMenuLeafDTO => {
    return menuDTO.treeType === "ASONewUiMenuLeafDTO"
}

export const isDashboardsEmbeddedMenuLeaf = (menuDTO: MenuDTO): menuDTO is DashboardsEmbeddedMenuLeafDTO => {
    return menuDTO.treeType === "DashboardsEmbeddedMenuLeafDTO"
}

export const isClassicUiEmbeddedMenuLeaf = (menuDTO: MenuDTO): menuDTO is ClassicUiEmbeddedMenuLeafDTO => {
    return menuDTO.treeType === "ClassicUiEmbeddedMenuLeafDTO"
}

export enum ActionIdentifier {
    CREATE = "CREATE",
    EDIT = "EDIT",
    DELETE = "DELETE",
    DEACTIVATE = "DEACTIVATE",
    ACTIVATE = "ACTIVATE",
    DOWNLOAD = "DOWNLOAD",
}
