import React, { useEffect, useState } from "react"
import { log } from "shared/util/log"
import { AppContextDTO } from "generated/models"
import { useArticleLayoutContext } from "domain/content/ArticleLayoutContext"
import { MESSAGE } from "domain/messaging/MessageListener"
import {
    DRAWER_WIDTH_FLOATING_MODE,
    DRAWER_WIDTH_LARGE_SCREEN,
    TOP_BAR_HEIGHT_LARGE_SCREEN,
    TOP_BAR_HEIGHT_SMALL_SCREEN,
} from "layout/MainLayout/constants"
import { Box, CircularProgress, useTheme } from "@mui/material"
import { SelectedState } from "domain/ColumnConfigurator/components/types"
import { WidgetStaticConfiguration } from "domain/ColumnConfigurator/types"
import { ColumnConfiguratorContextSlices } from "domain/ColumnConfigurator/context/ColumnConfiguratorContextSlices"
import { EasyXdmDashboardsEmbeddingAPI } from "embedding/dashboards/dashboards_embedding_api"
import { useDrawerContext } from "layout/MainLayout/Drawer/DrawerContext"

declare const baseUrlFrontend: string
declare const window: any

const socketName = "socket-newui-dashboards"
const containerId = "iframe-newui-dashboards"

const MESSAGE_RELOAD = "reload"
const MESSAGE_LOGIN_SUCCESS = "logInSuccess"

// define the data haptics logger to see error messages
window.DataHaptics = {}
window.DataHaptics.Logger = {
    logInfo: (msg) => {
        log.info(msg)
    },

    logError: (msg) => {
        log.error(msg)
    },
}

interface DashboardsEmbeddingSettings {
    singleDashboardName?: string
    lazyOpenAllDashboardsForDashboardGroup?: string
}

interface Props {
    url: string
    appContextDTO: AppContextDTO
    dashboardId?: number
    baseDashboardId?: string
    dashboardGroup?: string
}

const DashboardsEmbeddingAPI: React.FC<Props> = (props: Props): JSX.Element => {
    const articleLayoutContext = useArticleLayoutContext()
    const { isDrawerOpen } = useDrawerContext()
    const {
        open: openColumnConfigurator,
        dialogState: columnConfiguratorDialogState,
        getColumnConfiguratorOutputConfiguration,
    } = ColumnConfiguratorContextSlices.useWidgetState()
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        EasyXdmDashboardsEmbeddingAPI.setConfiguration({
            localHelperHtml: `${baseUrlFrontend}/asset/js/easyXDM/name.html`,
            reportingServerUrl: props.url,
            mainSocketName: socketName,
        })
        // TODO: is it safe to add the missing dependencies?
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    /**
     * Handler for processing messages from embedded elements.
     * Expecting that the message contains a json string with type and body fields.
     *
     * @param message
     * @param originUrl
     */
    const onMessageHandler = (message: string, originUrl: string) => {
        log.debug("Received message:" + message + ", origin:" + originUrl)

        // "logInSuccess" and "reload" messages is not in the json format
        switch (message) {
            case MESSAGE_RELOAD:
                log.error("Can not load the dashboards, reload message received")
                // return false to prevent reloading the page
                return false
            case MESSAGE_LOGIN_SUCCESS:
                setLoading(false)
                log.info("User logged in successfully")
                return false
            default:
                break
        }

        let messageJson
        try {
            messageJson = JSON.parse(message)
        } catch (error) {
            log.error("Error while parsing message json", error)
            return
        }

        switch (messageJson.type) {
            case MESSAGE.OPEN_HELP_ARTICLE:
                articleLayoutContext.openMenuWithFileName(messageJson.body.path)
                break
            case MESSAGE.OPEN_COLUMN_CONFIGURATOR:
                openColumnConfigurator(
                    new SelectedState(messageJson.body.state),
                    new WidgetStaticConfiguration(messageJson.body.config),
                )
                break
            default:
                log.warn(`Unsupported message type ${messageJson.type}`)
                break
        }
    }

    useEffect(() => {
        changeAppFilter(props.appContextDTO)
        // TODO: is it safe to add the missing dependencies?
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(props.appContextDTO)])

    useEffect(() => {
        if (columnConfiguratorDialogState === "applied") {
            applyColumnConfiguratorSettings(getColumnConfiguratorOutputConfiguration())
        }
        // TODO: is it safe to add the missing dependencies?
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [columnConfiguratorDialogState])

    useEffect(() => {
        if (props.baseDashboardId) {
            openDashboardForBackendBaseId(props.baseDashboardId)
        }
        // TODO: is it safe to add the missing dependencies?
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.baseDashboardId])

    useEffect(() => {
        if (props.dashboardGroup) {
            openDashboardGroup(props.dashboardGroup)
        }
        // TODO: is it safe to add the missing dependencies?
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.dashboardGroup])

    useEffect(() => {
        if (!props.baseDashboardId && !props.dashboardGroup) {
            openDashboardsList()
        }
        // TODO: is it safe to add the missing dependencies?
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.baseDashboardId, props.dashboardGroup])

    const changeAppFilter = (appFilter) => {
        EasyXdmDashboardsEmbeddingAPI.changeAppFilter(socketName, appFilter)
    }
    const applyColumnConfiguratorSettings = (selectedState: SelectedState) => {
        EasyXdmDashboardsEmbeddingAPI.applyColumnConfiguratorSettings(socketName, selectedState)
    }

    const openDashboardForBackendBaseId = (backendBaseId: string) => {
        const settings: DashboardsEmbeddingSettings = {
            singleDashboardName: backendBaseId,
        }
        embedPage(settings)
    }
    const openDashboardGroup = (dashboardGroup: string) => {
        const settings: DashboardsEmbeddingSettings = {
            lazyOpenAllDashboardsForDashboardGroup: dashboardGroup,
        }
        embedPage(settings)
    }
    const openDashboardsList = () => {
        embedPage({})
    }

    const embedPage = (settings: DashboardsEmbeddingSettings) => {
        setLoading(true)
        const endsWithSlash = props.url.endsWith("/")
        const url = endsWithSlash ? props.url : props.url + "/"

        EasyXdmDashboardsEmbeddingAPI.embedPage(
            url,
            socketName,
            containerId,
            props.appContextDTO,
            settings,
            onMessageHandler,
            null,
        )
    }

    const theme = useTheme()
    return (
        <Box
            className={"dashboards-embedding-api"}
            sx={{
                position: "absolute",
                right: 0,
                bottom: 0,
                overflow: "hidden",
                left: isDrawerOpen ? DRAWER_WIDTH_LARGE_SCREEN : DRAWER_WIDTH_FLOATING_MODE,
                top: TOP_BAR_HEIGHT_LARGE_SCREEN,
                [theme.breakpoints.down("lg")]: {
                    top: TOP_BAR_HEIGHT_SMALL_SCREEN,
                },
            }}
        >
            {loading && (
                <div
                    style={{
                        width: "100%",
                        height: "100%",
                        position: "absolute",
                        left: 0,
                        top: 0,
                        backgroundColor: "#FFF",
                    }}
                >
                    <div className="screen-centered">
                        <CircularProgress />
                    </div>
                </div>
            )}
            <div id={containerId} style={{ width: "100%", height: "100%" }}>
                Loading...
            </div>
        </Box>
    )
}

export default DashboardsEmbeddingAPI
